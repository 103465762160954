import * as PrismicHelpers from './helpers/helpers';
import { poiColours, poiPlaces } from '../../config/constants';

export const pageBar = (index, component) => ({
  link: PrismicHelpers.link(component.primary.url, component.primary.text),
  key: index
});

export const navigation = (index, component) => {

  const buildlinks = (ctas) => ctas.map(a => ({ ...a, ...PrismicHelpers.link(a.url, a.text) }));

  return {
    key: index,
    logo: component.data.svg && component.data.svg.url,
    stickyLogo: component.data.scrolled_svg && component.data.scrolled_svg.url,
    alt: (component.data.svg && component.data.svg.alt) || (component.data.scrolled_svg && component.data.scrolled_svg.alt),
    routes: PrismicHelpers.navigation(component.data.links),
    floatingCTALinks: component.data.ctas ? buildlinks(component.data.ctas) : []
  };
}

export const connectionTimes = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  subtitle: PrismicHelpers.plainText(component.primary.subtitle),
  connectionTimesImages: [
    component.primary.first_image.url,
    component.primary.second_image.url,
    component.primary.third_image.url,
    component.primary.fourth_image.url
  ]
})

export const footer = (index, component) => ({
  key: index,
  showFeed: PrismicHelpers.yesNo(component.data.show_feed),
  pages: PrismicHelpers.footerPages(component.data.pages),
  social: PrismicHelpers.footerSocial(component.data.social_links),
  phone: PrismicHelpers.plainText(component.data.phone_number),
  text: PrismicHelpers.richText(component.data.text),
  graingerInfo: PrismicHelpers.plainText(component.data.grainger_info)
});

export const cookiebar = (index, component) => ({
  key: index,
  text: PrismicHelpers.richText(component.text)
});

export const accreditation = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  text: PrismicHelpers.richText(component.primary.text),
  logos: PrismicHelpers.accreditationLogos(component.items)
});

export const amenities = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  amenities: PrismicHelpers.amenities(component.items),
  more: PrismicHelpers.richText(component.primary.more),
  cta: PrismicHelpers.link(component.primary.link_location, component.primary.link_text),
});

export const brandProperties = (index, component) => ({
  key: index,
  properties: PrismicHelpers.brandProperties(component.items),
  title: PrismicHelpers.title(component.primary.title),
  backgroundColour: PrismicHelpers.themePicker(component.primary.background_colour)
});

export const brandMarketing = (index, component) => ({
  key: index,
  title: PrismicHelpers.title(component.primary.title),
  description: PrismicHelpers.title(component.primary.description),
  backgroundColour: PrismicHelpers.themePicker(component.primary.background_colour)
});

export const commute = (index, component) => ({
  key: index,
  name: PrismicHelpers.title(component.primary.name),
  destinations: PrismicHelpers.commuteList(component.items)
});

export const ctaSection = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  text: PrismicHelpers.richText(component.primary.text),
  cta: PrismicHelpers.link(component.primary.link_location, component.primary.link_text)
});

export const ctaText = (index, component) => ({
  key: index,
  text: PrismicHelpers.richText(component.primary.text),
  title: PrismicHelpers.plainText(component.primary.title),
  cta: PrismicHelpers.link(component.primary.cta, component.primary.cta_text)
});

export const ctaBanner = (index, component) => ({
  image: component.primary.cta_icon.url,
  key: index,
  text: PrismicHelpers.plainText(component.primary.text),
  title: PrismicHelpers.plainText(component.primary.title),
  cta: PrismicHelpers.link(component.primary.link_location, component.primary.link_text)
});

export const digitalWalkthrough = (index) => ({
  key: index
});

export const floatingTextImage = (index, component) => ({
  key: index,
  image: component.primary.image.url,
  title: PrismicHelpers.plainText(component.primary.title),
  text: PrismicHelpers.plainText(component.primary.text)
});

export const floorPlans = (index, component) => {
  const IMAGES = PrismicHelpers.floorplans(component.items);

  return ({
    key: index,
    apartmentImages: IMAGES.apartmentImages,
    floorplanImages: IMAGES.modalImages,
    text: PrismicHelpers.richText(component.primary.text),
    url: PrismicHelpers.plainText(component.primary.digital_walkthrough_url)
  });
};

export const gallery = (index, component) => ({
  key: index,
  title: PrismicHelpers.richText(component.primary.title),
  isBackgroundWhite: PrismicHelpers.yesNo(component.primary.is_background_white),
  images: PrismicHelpers.gallery(component.items),
});

export const graingerAward = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  image: component.primary.award_icon.url,
  description: PrismicHelpers.richText(component.primary.description),
  cta: PrismicHelpers.link(component.primary.button_link, component.primary.button_text)
});

export const heroBanner = (index, component) => ({
  key: index,
  items: PrismicHelpers.heroCarousel(component.items)
});

export const heroBox = (index, component) => ({
  key: index,
  backgroundImage: component.primary.image.url,
  title: PrismicHelpers.richText(component.primary.title),
  subtitle: PrismicHelpers.richText(component.primary.subtitle),
  isFiltered: PrismicHelpers.yesNo(component.primary.filter)
});

export const heroVideo = (index, component) => ({
  key: index,
  video: component.primary.video.url,
  title: PrismicHelpers.richText(component.primary.title)
});

export const imageLinks = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  isBackgroundWhite: PrismicHelpers.yesNo(component.primary.is_background_white),
  subtitle: PrismicHelpers.plainText(component.primary.subtitle),
  links: PrismicHelpers.imageList(component.items),
  isAvailableProperties: PrismicHelpers.yesNo(component.primary.is_available_properties)
});

export const imageLinksTabs = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  links: PrismicHelpers.imageList(component.items),
});

export const imageSection = (index, component) => ({
  key: index,
  backgroundImage: component.primary.image.url,
  title: PrismicHelpers.plainText(component.primary.title),
  cta: PrismicHelpers.link(component.primary.cta_link, component.primary.cta_text)
});

export const landingPage = (index, component) => ({
  key: index,
  backgroundimage: component.primary.backgroundimage.url,
  logo: component.primary.logo && component.primary.logo.url,
  title: PrismicHelpers.title(component.primary.title),
  subtitle: PrismicHelpers.title(component.primary.subtitle),
  cta: PrismicHelpers.link(component.primary.cta, ''),
  isFiltered: PrismicHelpers.yesNo(component.primary.filter)
});

export const poiMap = (index) => ({
  key: index,
  items: poiPlaces,
  colours: poiColours,
  zoom: 14,
  locationCenter: {lat: 53.4674086, lng: -2.2870363}
});

export const simpleMap = (index, component) => {
  const centre = component.primary.centre.split(',');
  component.items.reduce((idx, it) => {
    it.id = idx;
    idx++;
    return idx;
  }, 1);

  return {
    zoom: "17",
    key: index,
    items: component.items,
    title: PrismicHelpers.title(component.primary.title),
    subtitle: PrismicHelpers.title(component.primary.subtitle),
    locationCenter: {lat: Number(centre[0]), lng: Number(centre[1])}
  };
};

export const contactBlockSection = (index, component) => ({
  key: index,
  backgroundImage: component.primary.image.url,
  title: PrismicHelpers.plainText(component.primary.title),
  description: PrismicHelpers.richText(component.primary.description),
  ctas: component.items.map(a => PrismicHelpers.link(a.link_location, a.link_text)),
  flip: PrismicHelpers.yesNo(component.primary.is_flipped)
})

export const propertyManagers = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  subtitle: PrismicHelpers.richText(component.primary.subtitle),
  managers: PrismicHelpers.propertyManagers(component.items)
});

export const quoteBlock = (index, component) => ({
  key: index,
  quote: PrismicHelpers.plainText(component.primary.quote),
  author: PrismicHelpers.plainText(component.primary.author),
  isFloated: PrismicHelpers.yesNo(component.primary.is_floated),
  isDarkTheme: PrismicHelpers.yesNo(component.primary.is_dark_theme)
});

export const rentalProcess = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  processes: PrismicHelpers.rentalProcessList(component.items)
});

export const rentingProcess = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  processes: PrismicHelpers.rentingProcess(component.items)
});

export const walkScore = (index, component) => ({
  key: index,
  location: PrismicHelpers.plainText(component.primary.location),
  body: PrismicHelpers.plainText(component.primary.body_text),
  link: PrismicHelpers.link(component.primary.walkscore_link, component.primary.walkscore)
});

export const imageTiles = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  tabAmount: component.primary.tabamount,
  images: PrismicHelpers.imageTileList(component.items)
});

export const renderContent = (title, content) => ({
  title: title,
  content: PrismicHelpers.richText(content)
});

export const repeatedGraphic = (index, component) => ({
  key: index,
  image: component.primary.image.url,
});

export const lookingToRent = (index, component) => ({
  key: index,
  title: PrismicHelpers.plainText(component.primary.title),
  apartmentOptions: component.items.map(a => {
    const temp = PrismicHelpers.link(a.link_location)
    temp.text = a.apartment
    return temp;
  }),
});
